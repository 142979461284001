import { createRoot } from "react-dom/client";
import Chat from "./chat";

const container = document.createElement("div");

document.body.appendChild(container);

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);

root.render(<Chat />);
