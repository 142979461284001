import { css } from "@emotion/react";
import { useRef, useState } from "react";
import { Send } from "./icons";

export interface InputProps {
  onMessageSend: (message: string) => void;
}

const Input = ({ onMessageSend }: InputProps) => {
  const [message, setMessage] = useState<string>("");
  const messageRef = useRef<HTMLInputElement>(null);

  return (
    <div
      css={css`
        padding: 10px 20px;
      `}
    >
      <div
        css={css`
          background: #ffffff;
          border: 2px solid #cbced1;
          border-radius: 800px;
          display: flex;
          padding: 10px 10px 10px 15px;
        `}
      >
        <input
          ref={messageRef}
          type="text"
          css={css`
            align-items: center;
            appearance: none;
            border: 0px;
            display: flex;
            flex-grow: 1;
            font-family: "Poppins";
            font-size: 13px;
            line-height: 1.5em;
            margin-right: 5px;
            outline: none;
          `}
          placeholder="Escrever mensagem..."
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter" && message.length > 0) {
              e.preventDefault();
              onMessageSend(message);
              setMessage("");
              messageRef.current?.focus();
            }
          }}
        />

        <div
          css={css`
            align-items: center;
            cursor: pointer;
            background-color: #dddddf;
            border-radius: 50%;
            display: flex;
            padding: 5px;
          `}
          onClick={() => {
            if (message.length > 0) {
              onMessageSend(message);
              setMessage("");
              messageRef.current?.focus();
            }
          }}
        >
          <img
            src={Send}
            css={css`
              align-items: center;
              cursor: pointer;
              display: flex;
            `}
          />
        </div>
      </div>
    </div>
  );
};

export default Input;
