import { useRef, useState } from "react";
import { ContainerControls, RoundedButton, VideoContainer } from "./styles";
import { Pause, PlayWhite } from "../../icons";


interface IVideoPreview {
  videoURL?: string;
  videoPoster?: string;
}

export const VideoPreview: React.FC<IVideoPreview> = ({ videoURL }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);

  const videoRef = useRef<HTMLVideoElement>(null);

  const togglePlay = () => {
    if(!videoRef.current) {
      return;
    }
    if (videoRef.current.paused) {
      videoRef.current.play();
      setIsPlaying(true);
    } else {
      videoRef.current.pause();
      setIsPlaying(false);
    }
  };

  const updateTime = () => {
    if(!videoRef.current) {
      return;
    }
    setCurrentTime(videoRef.current.currentTime);
  };

  // Function to update duration
  const updateDuration = () => {
    if(!videoRef.current) {
      return;
    }
    setDuration(videoRef.current.duration);
  };

  // Function to handle seek
  const handleSeek = (event: React.ChangeEvent<HTMLInputElement>) => {
    if(!videoRef.current) {
      return;
    }
    const seekTime = Number(event.target.value);
    videoRef.current.currentTime = seekTime;
    setCurrentTime(seekTime);
  };

  return (
    <>
      <VideoContainer>
        <video
          ref={videoRef}
          src={videoURL}
          onTimeUpdate={updateTime}
          onLoadedMetadata={updateDuration}
          height="auto"
          style={{maxHeight: 600}}
        />
      </VideoContainer>
      <ContainerControls>
        <RoundedButton onClick={togglePlay}>
          <img
            src={isPlaying ? Pause : PlayWhite}
            alt="Play or Pauseaudios"
          />
        </RoundedButton>
        <input
          style={{ width: "100%" }}
          disabled={false}
          name="percentage"
          id="percentage"
          className="form-control"
          aria-describedby="percentage"
          placeholder="0"
          type="range"
          min={0}
          max={duration}
          value={currentTime}
          onLoadedMetadata={updateDuration}
          onChange={handleSeek}
        />
      </ContainerControls>
    </>
  );
};
