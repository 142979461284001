import { css } from "@emotion/react";
import { BotInfo } from "./chat";
import { Avatar } from "./icons";

export interface TitlebarProps {
  botInfo: BotInfo;
  onClose: () => void;
}

export default ({ botInfo, onClose }: TitlebarProps) => (
  <div
    css={css`
      align-items: center;
      background-color: ${botInfo.chatColor};
      border-radius: 20px 20px 0px 0px;
      color: #ffffff;
      display: flex;
      padding: 10px;
    `}
  >
    <img
      src={Avatar}
      css={css`
        margin-right: 10px;
      `}
    />

    <div>
      <div
        css={css`
          font-size: 18px;
        `}
      >
        {botInfo.name}
      </div>
      <div
        css={css`
          font-size: 12px;
        `}
      >
        {botInfo.target}
      </div>
    </div>

    <div
      css={css`
        cursor: pointer;
        padding: 12px;
        margin-left: auto;
      `}
      onClick={onClose}
    >
      <div
        css={css`
          border-bottom: 2px solid #ffffff;
          color: #ffffff;
          width: 20px;
          height: 2px;
        `}
      ></div>
    </div>
  </div>
);
