import { css } from "@emotion/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Controller, useForm } from "react-hook-form";
import { BotInfo } from "./chat";
import InputFone from "./input-fone";
import * as v from "./validation";
import { z } from "zod";

const InputStyle = css`
  width: 100%;
`;

const ErrorMessageStyle = css`
  color: #ff4d4f;
  margin-top: 5px;
  font-size: 12px;
`;

export interface WelcomeProps {
  botInfo: BotInfo;
  onConversationStarted: (data: {
    name: string | null;
    phone: string | null;
    email: string | null;
  }) => void;
}

const Welcome = ({ botInfo, onConversationStarted }: WelcomeProps) => {
  const schema = v.object({
    name: botInfo.nameOnStart
      ? v.string().superRefine(v.nonempty())
      : v.string().optional(),
    phone: botInfo.phoneOnStart
      ? v.array(v.string()).superRefine((val, ctx) => {
          if (val[0] === "" || val[1] === "") {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: "Campo obrigatório"
            });
          }
        })
      : v.array(v.string()).optional(),
    email: botInfo.emailOnStart
      ? v.string().superRefine(v.nonempty()).superRefine(v.email())
      : v.string().superRefine(v.email()).optional()
  });

  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm<{ name: string; phone: string[]; email: string }>({
    defaultValues: {
      name: "",
      phone: ["55", ""],
      email: ""
    },
    resolver: zodResolver(schema)
  });

  const sortedInteractions =
    botInfo.interactions &&
    [...botInfo.interactions].sort((a, b) => a.position - b.position);

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        gap: 20px;
        padding: 10px;
        overflow-y: auto;
      `}
    >
      <div
        css={css`
          display: flex;
          flex-direction: column;
          gap: 10px;
        `}
      >
        {sortedInteractions?.map((i, index) => (
          <div
            key={index}
            css={css`
              background-color: ${botInfo.chatColor};
              border-radius: 16px 16px 0px 16px;
              color: #ffffff;
              padding: 10px;
            `}
          >
            {i.interaction}
          </div>
        ))}
      </div>

      <form
        onSubmit={handleSubmit((data) =>
          onConversationStarted({
            name: data.name ? data.name : null,
            phone: data.phone ? data.phone[0] + data.phone[1] : null,
            email: data.email ? data.email : null
          })
        )}
        css={css`
          display: flex;
          flex-grow: 1;
          flex-direction: column;
          gap: 20px;
        `}
      >
        <div
          css={css`
            display: flex;
            flex-grow: 1;
            flex-direction: column;
            gap: 10px;
          `}
        >
          {botInfo.nameOnStart ? (
            <div>
              <Controller
                name="name"
                control={control}
                render={({ field }) => (
                  <InputText
                    {...field}
                    placeholder="Nome"
                    css={InputStyle}
                    className={errors.name?.message ? "p-invalid" : ""}
                  />
                )}
              />
              {errors.name?.message ? (
                <div css={ErrorMessageStyle}>{errors.name.message}</div>
              ) : null}
            </div>
          ) : null}

          {botInfo.phoneOnStart ? (
            <div
              css={css`
                padding-top: 5px;
              `}
            >
              <Controller
                name="phone"
                control={control}
                render={({ field }) => (
                  <InputFone
                    value={field.value}
                    onChange={(value) => field.onChange(value)}
                    className={errors.phone?.message ? "p-invalid" : ""}
                  />
                )}
              />
              {errors.phone?.message ? (
                <div css={ErrorMessageStyle}>{errors.phone.message}</div>
              ) : null}
            </div>
          ) : null}

          {botInfo.emailOnStart ? (
            <div>
              <Controller
                name="email"
                control={control}
                render={({ field }) => (
                  <InputText
                    {...field}
                    placeholder="E-mail"
                    css={InputStyle}
                    className={errors.email?.message ? "p-invalid" : ""}
                  />
                )}
              />
              {errors.email?.message ? (
                <div css={ErrorMessageStyle}>{errors.email.message}</div>
              ) : null}
            </div>
          ) : null}
        </div>

        <Button
          label="Iniciar conversa"
          outlined={true}
          css={css`
            width: 100%;
          `}
        />
      </form>
    </div>
  );
};

export default Welcome;
