import { css } from "@emotion/react";
import { RefObject, forwardRef, useEffect } from "react";
import { BotInfo } from "./chat";
import { ChatMessage } from "./window";
import { Message } from "./message";
import MessageButton from "./message-button";

export interface MessagesProps {
  messages: ChatMessage[];
  botInfo: BotInfo;
  onButtonClick: (label: string) => void;
  ref: RefObject<HTMLDivElement>;
}
const Messages = forwardRef<HTMLDivElement, MessagesProps>(
  ({ messages, botInfo, onButtonClick }, ref) => {
    const images = messages
      .flatMap(
        (message) =>
          message?.files?.filter((file) => file?.mime?.startsWith("image")) ||
          []
      )
      .map((file) => file.url);

    return (
      <div
        ref={ref}
        css={css`
          flex-grow: 1;
          display: flex;
          flex-direction: column;
          gap: 10px;
          padding: 10px;
          overflow-y: auto;
          width: 100%;
        `}
      >
        {messages.map((m) => (
          <div
            key={`${m.id}-m.${m.dateTime}`}
            css={css`
              display: flex;
              flex-direction: column;
              gap: 10px;
              width: 100%;
            `}
          >
            <Message
              dateTime={m.dateTime}
              sender={m.sender}
              botInfo={botInfo}
              message={m.message}
              files={m.files}
              urls={images}
            />

            <div
              css={css`
                display: flex;
                flex-direction: column;
                gap: 10px;
              `}
            >
              {m.buttons
                ? m.buttons.map((b) => (
                    <MessageButton
                      key={b.id}
                      label={b.label}
                      type={b.type}
                      link={b.link}
                      onClick={() => onButtonClick(b.label)}
                    />
                  ))
                : null}
            </div>
          </div>
        ))}
      </div>
    );
  }
);

export default Messages;
