import styled from "@emotion/styled";

export const VideoContainer = styled.div`
display: flex;
flex-direction: column;
/* position: relative; */
overflow: hidden;
width: 100%;
height: auto;
max-width: 800px;
`;

export const ContainerControls = styled.div`
display: flex;
flex-direction: row;
align-items: center;
justify-content: space-between;
width: 100%;
;
`;

export const RoundedButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #DADADA;
  border-radius: 50%;
  border: 1px solid rgba(33, 150, 243, 1);
  min-height: 1.5rem;
  min-width: 1.5rem;
  height: 1.5rem;
  width: 1.5rem;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  &:hover {
    cursor: pointer;
    background-color: #fbfaf6;
    transition: 0.2s ease-in-out;
  }
`;