import { css } from "@emotion/react";
import { format, parseISO } from "date-fns";
import { Galleria } from "primereact/galleria";
import { Fragment, useEffect, useRef, useState } from "react";
import { BotInfo } from "./chat";
import { AudioPlayer } from "./components/AudioPreview";
import { VideoPreview } from "./components/VideoPreview";
import { IFile } from "./window";

export interface MessageProps {
  dateTime: string;
  children?: React.ReactNode;
  sender?: string;
  botInfo: BotInfo;
  message: string;
  files: IFile[];
  urls?: string[];
}

const URL_REGEX =
  /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/iu;

const base64toBlob = (
  base64Data: string,
  contentType = "",
  sliceSize = 512
) => {
  const byteCharacters = atob(base64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });

  return blob;
};
const FileLinkURL = ({
  filename,
  file
}: {
  filename: string;
  message: string;
  file?: iFile;
}) => {
  return (
    <a href={file?.url} target="_blank" rel="noreferrer">
      {filename}
    </a>
  );
};
const FileImagePreview = ({ file, urls }: { file: iFile; urls: string[] }) => {
  const { mime } = file;
  const [activeIndex, setActiveIndex] = useState(0);
  const galleriaRef = useRef<Galleria>(null);

  const handleImageClick = (url: string) => {
    const index = urls.indexOf(url);
    if (index !== -1) {
      setActiveIndex(index);
      galleriaRef?.current?.show();
    }
  };

  if (mime.startsWith("image")) {
    return (
      <div
        css={css`
          display: flex;
          justify-content: center;
        `}
      >
        <img
          src={file.url}
          height={200}
          css={css`
            cursor: pointer;
            max-width: 100%;
          `}
          onClick={() => handleImageClick(file.url)}
        />
        <Galleria
          ref={galleriaRef}
          value={urls}
          circular={true}
          fullScreen={true}
          showItemNavigators={true}
          showThumbnails={true}
          numVisible={6}
          activeIndex={activeIndex}
          onItemChange={(e) => setActiveIndex(e.index)}
          item={(item) => (
            <img
              src={item}
              style={{ width: "90vw", height: "75vh", objectFit: "contain" }}
            />
          )}
          thumbnail={(item) => <img src={item} width={80} height={60} />}
          style={{ maxHeight: "80%", maxWidth: "80%" }}
        />
      </div>
    );
  }

  return null;
};

// const FileLink = ({ message, file }: { message: string; file: string }) => {
//   const [url, setUrl] = useState("");
//   const mime = file.substring(file.indexOf(":") + 1, file.indexOf(","));
//   useEffect(() => {
//     const blob = base64toBlob(file.substring(file.indexOf(",") + 1), mime);
//     const url = URL.createObjectURL(blob);

//     setUrl(url);

//     return () => {
//       if (url) {
//         URL.revokeObjectURL(url);
//       }
//     };
//   }, [file, mime]);

//   if (mime.startsWith("image")) {
//     return (
//       <div
//         css={css`
//           overflow-wrap: break-word;
//         `}
//       >
//         {message}
//       </div>
//     );
//   }

//   return (
//     <a
//       href={url}
//       target="_blank"
//       rel="noreferrer"
//       css={css`
//         overflow-wrap: break-word;
//       `}
//     >
//       {message}
//     </a>
//   );
// };

export const Message = ({
  dateTime,
  sender,
  botInfo,
  message,
  files,
  urls
}: MessageProps) => {
  const messageContent = message.split("\n").map((m, index) => {
    const content = m.split(" ").map((mm, mIndex) =>
      URL_REGEX.test(mm) ? (
        <Fragment key={mIndex}>
          <a
            href={
              mm.includes("https://") || mm.includes("http://")
                ? mm
                : `https://${mm}`
            }
            target="_blank"
            rel="noreferrer"
          >
            {mm}
          </a>{" "}
        </Fragment>
      ) : (
        <Fragment key={mIndex}>{mm} </Fragment>
      )
    );

    return <div key={index}>{content}</div>;
  });

  return (
    <div
      css={css`
        align-items: ${sender === "CLIENT" ? "flex-end" : "flex-start"};
        display: flex;
        flex-direction: column;
        font-size: 14px;
        overflow: visible;
        width: 100%;
      `}
    >
      <div
        css={css`
          background: ${sender === "CLIENT"
            ? botInfo.chatColor ||
              "linear-gradient(90deg, #046af3 0%, #4694fe 100%)"
            : "#f0eff5"};
          border-radius: ${sender === "CLIENT"
            ? "16px 16px 0px 16px"
            : "16px 16px 16px 0px"};
          color: ${sender === "CLIENT" ? "#ffffff" : "#0f1828"};
          padding: 10px;

          a {
            color: ${sender === "CLIENT" ? "#ffffff" : "#046AF3"};
          }

          max-width: 100%;
        `}
      >
        {files
          ? files?.map((file, idx) => {
              const fileName = file?.name || (file?.fileName as string);
              if (file.mime.startsWith("image")) {
                return <FileImagePreview key={idx} file={file} urls={urls} />;
              }
              if (file.mime.startsWith("audio")) {
                // return <FileAudioPreview key={idx} file={file} />
                return (
                  <AudioPlayer url={file.url} fileId={file.id} key={idx} />
                );
              }
              if (file.mime.startsWith("video")) {
                return <VideoPreview videoURL={file.url} key={idx} />;
              }
              return (
                <FileLinkURL
                  filename={fileName}
                  message={message}
                  key={idx}
                  file={file}
                />
              );
            })
          : null}
        <div
          css={css`
            margin-top: 0.3rem;
            overflow-wrap: break-word;
            word-wrap: break-word;
          `}
        >
          {message}
        </div>
        <div
          css={css`
            color: ${sender === "CLIENT" ? "#ffffff" : "#adb5bd"};
            font-size: 10px;
            margin-top: 5px;
            text-align: right;
          `}
        >
          {format(parseISO(dateTime), "HH:mm")}
        </div>
      </div>
    </div>
  );
};
