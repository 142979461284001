/* eslint-disable no-useless-escape */
/* eslint-disable no-control-regex */
import { z } from "zod";

const emailRegex =
  /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/iu;

export const { string } = z;

export const { object } = z;

export const { array } = z;

export const email = () => (val: string, ctx: z.RefinementCtx) => {
  if (val && !emailRegex.test(val)) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      params: { validation: "email" }
    });
  }
};

export const nonempty = () => (val: string, ctx: z.RefinementCtx) => {
  if (val !== undefined && val !== null && val.trim() === "") {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      params: { validation: "string_vazia" }
    });
  }
};

z.setErrorMap((error, ctx) => {
  if (error.message) {
    return { message: error.message };
  }

  switch (error.code) {
    case z.ZodIssueCode.invalid_type:
      if (error.received === "undefined" || error.received === "null") {
        return { message: "Campo obrigatório" };
      }

      if (error.expected === "date") {
        return { message: "Data inválida" };
      }

      break;

    case z.ZodIssueCode.too_small:
      if (error.type === "string" && error.minimum === 1) {
        return { message: "Campo obrigatório" };
      } else if (error.type === "array") {
        return { message: "Deve conter no mínimo um registro" };
      } else if (
        error.type === "number" &&
        !error.inclusive &&
        error.minimum === 0
      ) {
        return { message: "Deve ser maior que 0" };
      }

      break;

    case z.ZodIssueCode.too_big:
      return { message: `Máximo de ${error.maximum} caracteres` };

    case z.ZodIssueCode.custom:
      if (error.params) {
        switch (error.params.validation) {
          case "email":
            return { message: "E-mail inválido" };

          case "url":
            return { message: "URL inválida" };

          case "cpf_cnpj":
            return { message: "CPF/CNPJ inválido" };

          case "cep":
            return { message: "CEP inválido" };

          case "fone":
            return { message: "Fone inválido" };

          case "id":
            return { message: "Registro inexistente" };

          case "unique":
            return { message: "Não pode conter elementos duplicados" };

          case "string_vazia":
            return { message: "Campo obrigatório" };

          default:
            break;
        }
      }

      break;

    case z.ZodIssueCode.invalid_union:
      return { message: "Campo obrigatório" };

    default:
      break;
  }

  return { message: ctx.defaultError };
});
