import { css } from "@emotion/css";
import styled from "@emotion/styled";

export const Modal = styled.dialog`
  display: flex;
  padding: 1rem;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #0a043c;
  background-color: white;
  border-radius: 1rem;
  border: 1px solid #000;
  overflow-y: scrool;
  min-height: 150px;
  width: 100%;
  bottom: 0;
  left: 0;
  box-shadow: rgba(0, 0, 0, 0.3);
`;
export const Overlay = styled.div`
  position: absolute;
  z-index: 11;
  min-height: 100%;
  min-width: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
`;

export const Textp = css`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #544f77;
  text-align: left;
`;

export const WaveformContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  width: 100%;
  background: transparent;
  /* gap: 2rem; */
`;
export const NewWaveformContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0.5rem;
  gap: 0.5rem;
  justify-content: space-between;
  height: 2.5rem;
  width: 18rem;
  background: transparent;
  border: 1px solid #dadada;
  border-radius: 1.25rem;
  /* gap: 2rem; */
`;
export const Wave = styled.div`
  /* position: absolute; */
  /* z-index: 12; */
  margin-top: -9rem;
  width: 100%;
  /* height: "-moz-ava/ilable"; */
  height: 90px;
`;
export const PlayButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 60px;
  min-height: 60px;
  background: #efefef;
  border-radius: 50%;
  border: none;
  outline: none;
  cursor: pointer;
  padding-bottom: 3px;
  &:hover {
    background: #ddd;
  }
`;

export const RoundedButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #dadada;
  border-radius: 50%;
  border: 1px solid rgba(33, 150, 243, 1);
  min-height: 1.5rem;
  min-width: 1.5rem;
  height: 1.5rem;
  width: 1.5rem;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  &:hover {
    cursor: pointer;
    background-color: #fbfaf6;
    transition: 0.2s ease-in-out;
  }
`;
export const RoundedButtonGreen = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #00cdac;
  border-radius: 50%;
  border: none;
  min-height: 1.5rem;
  min-width: 1.5rem;
  height: 1.5rem;
  width: 1.5rem;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;

  &:hover {
    cursor: pointer;
    background-color: #53d9c3;
    transition: 0.2s ease-in-out;
  }
`;

export const WaveContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  flex-grow: 1;
  height: 100%;
  div {
    width: 100%;
    max-height: 15px;
    margin-top: 1rem;
    position: relative;
    wave {
      width: 100%;
      display: unset !important;
      max-height: 30px !important;
      /* position: absolute !important; */
      /* top: 50% !important; */
      left: 0;
      right: 0;
    }
  }
`;
interface IText {
  s?: string;
  lh?: string;
  color?: string;
  weight?: number;
}
export const Text = styled.p<IText>`
  font-family: "Poppins";
  font-style: normal;
  font-weight: ${(props) => (props.weight ? props.weight : 400)};
  font-size: ${(props) => (props.s ? props.s : "12px")};
  line-height: ${(props) => (props.lh ? props.lh : "16px")};
  text-align: center;
  color: ${(props) => (props.color ? props.color : "#6E6B7B")};
`;
