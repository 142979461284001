// interface IOptionsRobotActions {

import React, { useState, useRef, useEffect } from "react";
import WaveSurfer from "wavesurfer.js";

import {
  NewWaveformContainer,
  RoundedButton,
  WaveContainer,
  Text
} from "./styles";
import { Pause, PlayWhite } from "../../icons";
import { ClockAudio } from "../ClockAudio";
interface IAudioPlayer {
  url: string;
}
// audio/ogg
export const AudioPlayer: React.FC<IAudioPlayer> = ({ url }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const waveSurferRef = useRef<WaveSurfer>(null);
  const waveformRef = useRef(null);

  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [indexSpeed, setindexSpeed] = useState(0);
  const speeds = [1, 1.5, 2];
  const id = `#Ws${url.slice(url.lastIndexOf("-")+1)}`;
  useEffect(() => {
    // if(waveSurferRef?.current) {
      if (!waveformRef.current) {
        return;
      }
      waveSurferRef.current = WaveSurfer.create({
      container: waveformRef.current,
      waveColor: "blue",
      progressColor: "green"
    });
      waveSurferRef.current?.load(url);
      setDuration(waveSurferRef.current?.getDuration() as number);
    // eslint-disable-next-line consistent-return
    return () => {
      if (waveSurferRef.current) {
        waveSurferRef.current.destroy();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url, waveformRef?.current, waveSurferRef?.current]);

  useEffect(() => {
    let setIntervall: string | number | NodeJS.Timeout | undefined;

    waveSurferRef?.current?.on("ready", () => {
      setDuration(waveSurferRef?.current?.getDuration() as number);
      setCurrentTime(0);
    });
    waveSurferRef?.current?.on("play", () => {
      setIsPlaying(true);
      setIntervall = setInterval(() => {
        setCurrentTime(waveSurferRef?.current?.getCurrentTime() as number);
      }, 1000);
      setDuration(waveSurferRef?.current?.getDuration() as number);
    });
    waveSurferRef?.current?.on("finish", () => {
      setIsPlaying(false);
      waveSurferRef?.current?.setCurrentTime(0);
      setCurrentTime(0);
      setDuration(waveSurferRef?.current?.getDuration() as number);
      clearInterval(setIntervall);
    });
    waveSurferRef?.current?.on("pause", () => {
      setCurrentTime(waveSurferRef?.current?.getCurrentTime() as number);
      setIsPlaying(false);
      clearInterval(setIntervall);
    });
    // }
    if (isPlaying === false) {
      clearInterval(setIntervall);
    }
    return clearInterval(setIntervall);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPlaying, currentTime, duration]);

  const togglePlay = () => {
    if (isPlaying) {
      waveSurferRef?.current?.pause();
      setIsPlaying(false);
    } else {
      waveSurferRef?.current?.on("ready", () => {
        setDuration(waveSurferRef?.current?.getDuration() as number);
        setCurrentTime(0);
      });
      waveSurferRef?.current?.play();
      setIsPlaying(true);
    }
  };
  const changeSpeed = () => {
    if (indexSpeed === 2) {
      setindexSpeed(0);
    } else {
      setindexSpeed(indexSpeed + 1);
    }
    // waveSurferRef?.current?.setPlaybackRate(waveSurferRef?.current?.getPlaybackRate(), true)
    waveSurferRef?.current?.setPlaybackRate(speeds[indexSpeed + 1]);
    // waveSurferRef?.current?.play();
  };
  return (
    <NewWaveformContainer>
      <ClockAudio
        isPlaying={isPlaying}
        timeTotal={duration}
        timeCurrent={currentTime}
      />
      <WaveContainer>
        <div id={id} ref={waveformRef}></div>
      </WaveContainer>
      <RoundedButton onClick={changeSpeed}>
        <Text s="0.7rem">{speeds[indexSpeed]}x</Text>
      </RoundedButton>
      <RoundedButton onClick={togglePlay}>
        <img src={isPlaying ? Pause : PlayWhite} alt="Play or Pauseaudios" />
      </RoundedButton>
    </NewWaveformContainer>
  );
};
