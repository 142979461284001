import { css } from "@emotion/react";
import { useEffect, useRef, useState } from "react";
import useWebSocket, { ReadyState } from "react-use-websocket";
import { JsonArray, JsonObject } from "react-use-websocket/dist/lib/types";
import { BotInfo } from "./chat";
import Input from "./input";
import Messages from "./messages";
import StatusBar from "./status-bar";
import TitleBar from "./title-bar";
import Welcome from "./welcome";

declare global {
  interface Window {
    icwToken: string;
  }
}

export interface ChatMessageButton extends JsonObject {
  id: number;
  position: number;
  label: string;
  type: string;
  link: string;
}
export interface IFile extends JsonObject {
  id: number;
  mime: string;
  name: string;
  url: string;
}
export interface ChatMessage extends JsonObject {
  id: number;
  dialogId: number;
  sender: string;
  dialogToken: string;
  message: string;
  buttons: ChatMessageButton[];
  dateTime: string;
  file: string;
  files: IFile[];
}

interface ChatMessageJson extends JsonObject {
  id: number;
  dialogId: number;
  sender: string;
  dialogToken: string;
  message: JsonArray;
  buttons: ChatMessageButton[];
  dateTime: string;
  file: string;
  files: IFile[];
}

export interface WindowProps {
  minimized: boolean;
  botInfo: BotInfo;
  onClose: () => void;
}

interface FormData {
  name: string | null;
  phone: string | null;
  email: string | null;
}

const Window = ({ minimized, botInfo, onClose }: WindowProps) => {
  const messageContainerRef = useRef<HTMLDivElement>(null);

  const [messageHistory, setMessageHistory] = useState<ChatMessage[]>([]);
  const { sendJsonMessage, lastJsonMessage } = useWebSocket<ChatMessageJson>(
    process.env.NODE_ENV === "development"
      ? `ws://localhost:${process.env.SERVER_EXTERNAL_PORT}/chat/${process.env.BOT_TOKEN}`
      : `wss://${process.env.CHAT_URL}/chat/${window.icwToken}`,
    {
      retryOnError: true,
      reconnectAttempts: 10,
      reconnectInterval: 5_000,
      heartbeat: {
        interval: 10_000,
        message: `{ "ping": true }`,
        returnMessage: "pong",
        timeout: 60_000
      }
    }
  );
  const [dialogToken, setDialogToken] = useState<string | null>(null);

  const [conversationStarted, setConversationStarted] = useState(false);

  const [formData, setFormData] = useState<FormData>();

  const scrollToBottom = () => {
    if (messageContainerRef.current) {
      messageContainerRef.current.scrollTop =
        messageContainerRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    const run = async () => {
      const storedDialogToken = localStorage.getItem("imbot-chat-dialogToken");
      if (storedDialogToken) {
        setDialogToken(storedDialogToken);
        const response = await fetch(
          process.env.NODE_ENV === "development"
            ? `/omnichannel/dialogs/${storedDialogToken}`
            : `https://${process.env.CHAT_URL}/omnichannel/dialogs/${storedDialogToken}`
        );

        const dialog = await response.json();
        if (dialog) {
          if (dialog.closedAt) {
            localStorage.removeItem("imbot-chat-dialogToken");
            setConversationStarted(false);
          } else if (dialog && Array.isArray(dialog.messages)) {
            setMessageHistory((prev) => [...prev, ...dialog.messages]);
            setConversationStarted(true);
          }
        }

        setTimeout(() => scrollToBottom(), 100);
      }
    };

    run();
  }, []);

  useEffect(() => {
    if (lastJsonMessage !== null) {
      setMessageHistory((prev) => [
        ...prev,
        ...lastJsonMessage.message.map((m) => ({
          ...lastJsonMessage,
          message: m.toString(),
          files: lastJsonMessage.files
        }))
      ]);

      localStorage.setItem("imbot-chat-dialogToken", `${dialogToken}`);

      setDialogToken(lastJsonMessage.dialogToken);

      setTimeout(() => scrollToBottom(), 100);
    }
  }, [lastJsonMessage]);

  return (
    <div
      css={css`
        background: #ffffff;
        border-radius: 20px 20px 0px 0px;
        bottom: 0px;
        box-shadow: -5px -5px 16px rgba(0, 0, 0, 0.1);
        display: ${minimized ? "none" : "flex"};
        flex-direction: column;
        height: 563px;
        position: fixed;
        overflow: hidden;
        right: 10px;
        width: 376px;

        color: #212529;
        font-family: "Poppins", sans-serif;
        font-size: 14px;
        font-weight: 400;
        line-height: 1.5;
      `}
      className="imbot-chat-maximized"
    >
      <TitleBar onClose={onClose} botInfo={botInfo} />

      {conversationStarted ? (
        <Messages
          ref={messageContainerRef}
          messages={messageHistory}
          botInfo={botInfo}
          onButtonClick={(label) => {
            const messageToSend = {
              dialogToken: dialogToken as string,
              message: label,
              ...formData
            };

            sendJsonMessage(messageToSend);

            setTimeout(() => scrollToBottom(), 100);
          }}
        />
      ) : null}

      {conversationStarted ? (
        <Input
          onMessageSend={(message) => {
            const messageToSend = {
              dialogToken: dialogToken as string,
              message,
              ...formData
            };

            sendJsonMessage(messageToSend);

            setTimeout(() => scrollToBottom(), 100);
          }}
        />
      ) : null}

      {conversationStarted ? null : (
        <Welcome
          botInfo={botInfo}
          onConversationStarted={(data) => {
            const messageToSend = {
              dialogToken: dialogToken as string,
              message: "Oi",
              ...data
            };

            setFormData(data);

            sendJsonMessage(messageToSend);

            setConversationStarted(true);
          }}
        />
      )}

      <StatusBar />
    </div>
  );
};

export default Window;
