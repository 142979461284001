import { css } from "@emotion/react";

export interface MessageButtonProps {
  label: string;
  type: string;
  link: string;
  onClick: () => void;
}

const Style = css`
  background-color: #ffffff;
  border: 1px solid #d2d2d2;
  border-radius: 8px;
  color: #212529;
  cursor: pointer;
  padding: 8px;
  text-decoration: none;

  &:hover {
    background-color: #046af3;
    border: 1px solid #046af3;
    box-shadow: 0px 4px 10px rgba(16, 156, 241, 0.24);
    color: #ffffff;
  }
`;

const MessageButton = ({ label, type, link, onClick }: MessageButtonProps) =>
  type === "QUICK_REPLY" || type === "0" ? (
    <div css={Style} onClick={onClick}>
      {label}
    </div>
  ) : (
    <a
      css={Style}
      target="_blank"
      href={
        link.includes("http://") || link.includes("https://")
          ? link
          : `https://${link}`
      }
      rel="noreferrer"
    >
      {label}
    </a>
  );

export default MessageButton;
