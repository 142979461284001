import { css } from "@emotion/react";

const StatusBar = () => (
  <div
    css={css`
      align-items: center;
      display: flex;
      color: #6c757d;
      font-size: 12px;
      margin-bottom: 10px;
      justify-content: center;
    `}
  >
    © imbot
  </div>
);

export default StatusBar;
